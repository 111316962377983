<template>
  <div :class="['bg',{'isMobile': isMobile, 'noPadding': !padding }]">
    <div class="page">
      <div class="square"></div>
      <div class="title" v-if="title">
        <h2>
          {{ title }}
        </h2>
      </div>
      <div class="line">
        <div class="innerline"></div>
      </div>
      <div class="content">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  props: {
    title: String,
  },
  padding: {
    type: Boolean,
    default: true
  }
};
</script>
<style lang="scss" scoped>
.bg {
  padding: 60px;
  .page {
    background-color: #fff;
    max-width: 995px;
    margin: auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.08);
    min-height: calc(100vh - 120px);
    .square {
      position: absolute;
      top: -85px;
      left: -106px;
      width: 190px;
      height: 190px;
      transform: rotate(-140deg);
      background: repeat center/11px url("~@/assets/dot.png");
      z-index: 1;
    }
    .title {
      padding-top: 40px;
      padding-bottom: 30px;
      padding-left: 50px;
      padding-right: 50px;
      text-align: left;
      margin-bottom: 0;
      h2 {
        font-size: 30px;
        font-weight: 800;
        color: $secondary;
        text-transform: capitalize;
        display: inline-block;
      }
    }
    .line {
      border-bottom: 1px solid grey;
      width: calc(100% - 100px);
      left: 50px;
      position: relative;
      height: 3px;
      .innerline {
        background-color: $primary;
        width: 55px;
        height: 3px;
      }
    }
    .content {
      padding: 70px 50px;
    }
  }
  &.isMobile {
      padding: 0px;
    .page {
      max-width: 100%;
      width: 100%;
    }
  }
}
.isMobile {
&.noPadding {
  .content {

    padding: 0!important;
  }
}
}
</style>
